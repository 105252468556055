@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,500;0,600;0,700;1,500;1,600&display=swap');

$dark-green: #233E43;
$green: #9CB59E;
$medium-green: #DDE3DB;
$light-green: #ECEFEB;
$blue: #4E7E89;
$light-blue: #DCE1E4;
$sienna: #996A36;
$berry: #90405D;

* {
  box-sizing: border-box;
}

html {
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  margin: 0;
  padding: 0;
  background: $dark-green;
  min-height: 100%;
  overflow-x: hidden;
}

.container {
  width: 100vw;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  justify-items: center;
}

main {
  width: 100vw;
  height: auto;
  min-height: 100vh;
}

///////////////////////////////////// Text


ul {
  list-style-type: none;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: #d3962a;
}

a:hover {
  color: #fff;
}


//////////////////////////// HOME

.wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-self: center;
  margin-top: 100px;
}

.sub-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.landing {
  display: flex;
  padding-top: 50px;
  align-self: center;
  justify-content: center;
  margin-right: 0;
  width: clamp(20rem, 90vw, 49.7rem);
  position: relative;
  margin-top: 30px;

  .land2 {
    display: inline-grid;
    width: 100%;
    height: 100%;
    position: relative;

    .par {
      display: inline-block;
      z-index: 2;
      position: relative;
    }

    p {
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      font-size: clamp(1rem, 3.5vw, 1.3rem);
      line-height: clamp(1.5rem, 6vw, 2rem);
      text-align: left;
      color: #fff;
      margin: 0;
      padding: 0;
      position: relative;
      z-index: 2;
      text-align: center;
    }

    .headcirc {
      align-self: center;
      justify-self: center;
      position: absolute;
      z-index: 1;
      width: clamp(20rem, 50vw, 45rem);
      height: clamp(20rem, 50vw, 45rem);
    }
  }
}

h1.home-text {
  position: relative;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: clamp(3rem, 15vw, 10rem);
  color: #fff;
  display: inline-block;
  margin: 0;
  width: auto;
  z-index: 2;
}

.line-wrap {
  span {
    padding: 0;
    display: block;
  }
}

.l1 {
  text-align: left;
  line-height: clamp(1.2rem, 9vw, 7rem);
}

.l2 {
  text-align: right;
  line-height: clamp(2rem, 20vw, 15rem);
}

.icons {
  background: rgba(221,227,219,1);
  width: 100%;
  height: calc(20vh + 100px);
}

.sectionhead {
  display: flex;
  align-self: start;
  justify-self: start;
  position: relative;
  z-index: 2;

  .htwocirc {
    position: absolute;
    z-index: -1;
    align-self: center;
    justify-self: center;
    width: clamp(20rem, 60vw, 32rem);
    height: clamp(20rem, 60vw, 32rem);
  }
}

h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: clamp(1.5rem, 8vw, 5.3rem);
  line-height: clamp(2rem, 8vw, 5.3rem);
  margin: 0;
  display: flex;
  flex-direction: column;
}

h2.blue {
  color: $blue;
  span {
    &:nth-of-type(2) {
      line-height: clamp(4.5rem, 9vw, 11rem);
      align-self: flex-end;
    }
  }
}

h2.white {
  color: #fff;
  .right {
    align-self: flex-end;
  }
}

span.und {
  position: relative;
  z-index: 1;
}

span.und::after {
  content:'';
  position:absolute;
  left: 0;
  bottom: 0;
  right:0;
  width: 100%;
  height: 100%;
  border-bottom: 1.5vw solid $sienna;
  z-index: -1;
}

/* WAVES */

.wavesbox {
display: flex;
justify-content: center;
width: 100%;
height: 20vh;
position: relative;
overflow: hidden;
margin-bottom: -1px;
}

.largeWaves {
display: block;
position: absolute;
width: 100%;
height: 100%;
bottom: 0;
}

.needs, .why, .customers {
  position: relative;
  width: 100%;
  // min-height: 100vh;
  padding: 150px 5vw 50px 5vw;
  display: grid;
  justify-content: flex-start;
}

.needs {
  padding-top: 100px;
}

.customers {
  padding-bottom: 150px;
}

.needs {

  background: $medium-green;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
   "header header"
   "blocks blocks";

   .sectionhead {
     grid-area: header;
   }
}

.blocks {
  grid-area: blocks;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 20px;
  justify-items: center;
  width: 100%;
  max-width: 50rem;
  padding: 0;
  position: relative;
  z-index: 2;
  justify-self: center;
  align-self: flex-start;
  // margin-top: -150px;

  .onearrow {
    display: flex;
    justify-content: center;
    position: relative;
    width: 1fr;
    max-width: 1fr;
    min-width: 1fr;
    max-width: 500px;
    height: 200px;
    z-index: 2;
    grid-row-start: 1;
    grid-column-start: 1;

    &:nth-of-type(2) {
      margin: 30px auto 0 auto;
      position: relative;
      grid-column-start: 2;
    }
  }

  .oneblock {
    display: flex;
    align-items: center;
    position: relative;
    background: $light-green;
    align-self: flex-start;
    justify-self: flex-start;
    grid-row-start: 2;
    width: 100%;
    height: auto;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: clamp(.8rem, 15px, 1.2rem);
    aspect-ratio: 4/3;
    border-radius: 20px;
    padding: 50px 12px 12px 12px;
    text-align: center;
    grid-column-start: 1;

    &:nth-of-type(2) {
      margin-top: 30px;
      grid-column-start: 2;
    }

    p {
      width: 100%;
      height: auto;
      word-wrap: normal;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      margin: 0 auto;
      top: -50px;
      left: calc(50% - 50px);
      width: 100px;
      height: 100px;
      background-image: url("/assets/boxcircle.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  } //end .one-block
} //end .blocks

.why {
  background: $dark-green;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
   "header header"
   "pops pops"
   "trusted trusted"
   "strat strat";
   grid-gap: 75px;

   .sectionhead {
     grid-area: header;
   }

   .popwords {
     grid-area: pops;
     display: flex;
     flex-direction: row;
     align-items: start;
     justify-content: space-between;
     position: relative;
     width: 100%;
     align-self: flex-start;
     z-index: 2;

     img {
       position: relative;
       height: 30%;
       max-height: 50px;
     }

     .wordbox {
       display: flex;
       flex-direction: column;
       gap: 20px;

       span {
         text-align: left;
         color: #fff;
         font-family: 'Raleway', sans-serif;
         font-weight: 400;
         font-size: clamp(1rem, 1.6vw, 1.1rem);
         text-align: center;

       }
     } //end .wordbox
   } //end .popwords

   h3 {
     grid-area: trusted;
     color: $green;
     font-family: 'Raleway', sans-serif;
     font-weight: 500;
     font-size: clamp(1rem, 5vw, 3rem);
     line-height: clamp(1rem, 5vw, 3rem);
     margin: 0 30px;
     align-self: flex-start;

     span {
       color: $sienna;
     }
   } //end h3

   .strategy {
     grid-area: strat;
     display: flex;
     align-items: center;
     position: relative;
     align-self: flex-start;
     width: 100%;
     padding: 30px 30px 0px 30px;
     opacity: 1;
     // z-index: 101;

     &:after {
       content: "";
       display: block;
       position: absolute;
       margin: 0 auto;
       top: 15px;
       left: 0;
       width: 100%;
       height: 100%;
       background: $green;
       border-radius: 30px;
       padding-bottom: 200px;
       z-index: 1;
     }

     p {
       color: $dark-green;
       font-family: 'Raleway', sans-serif;
       font-weight: 600;
       font-size: clamp(1rem, 1.3vw, 1.5rem);
       line-height: clamp(2rem, 2.3vw, 2.5rem);
       z-index: 2;
     }
   } //end .strategy
} //end .why


.customers {
  align-self: start;
  justify-self: start;
  background: $blue;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 75px;
  justify-items: center;

   h2 {
     margin: 0;
     justify-self: start;
   }

   .reviews {
     width: 100%;
     display: inline-flex;
     flex-wrap: wrap;
     align-items: center;
     align-content: center;
     justify-content: center;
     justify-items: center;
     padding: 0;
     z-index: 2;
     position: relative;

     &:after {
       content: "";
       display: block;
       position: absolute;
       top: 10%;
       left: 20vw;
       width: 100%;
       height: 95%;
       border: 1px solid $green;
       border-radius: 30px;
       z-index: -1;
     }

     .revblock {
       display: flex;
       align-items: center;
       background: $light-blue;
       height: auto;
       border-radius: 30px;
       padding: 30px 30px 30px 30px;
       margin: 25px;
       // width: clamp(6rem, 100%, 12rem);
       // height: clamp(7rem, 100%, 13rem);

       aspect-ratio: 1/1;
       flex-grow: 1;
       max-width: 350px;

       p {
         font-family: 'Raleway', sans-serif;
         font-weight: 400;
         font-size: clamp(1.1rem, 1.3vw, 1.2rem);
         line-height: clamp(1.7rem, 2.3vw, 2rem);
         display: flex;
         flex-direction: column;

         span {
           color: $berry;
           margin-top: 10px;
           font-style: italic;
           font-weight: 300;
         }
       } //end p
     } //end .revblock
   } //end .reviews
} //end .customers

.serv-blue {
  position: relative;
  width: 100%;
  padding: 50px 5vw 0px 5vw;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  background: $blue;

  .headcirc {
    position: relative;
    z-index: -1;
    width: clamp(15rem, 50%, 20rem);
  }

  .sectionhead {
    display: flex;
    align-self: start;
    justify-self: start;
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    z-index: 2;
    width: 100%;
  }

  h2.ourservices {
    color: $dark-green;
    font-size: clamp(2rem, 12vw, 5rem);
    position: absolute;
    z-index: 100;
  }
}

.par.ab {
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  background: $light-green;
  border-radius: 20px 20px 0 0;
  padding: 40px 40px 100px 40px;
  p {
    color: $dark-green;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: clamp(1rem, 3.5vw, 1.3rem);
    line-height: clamp(2rem, 6.5vw, 2.7rem);
    margin: 0;
  }
}

.aboutwaves {
 display: flex;
 justify-content: center;
 width: 100%;
 height: 20vh;
 position: relative;
 overflow: hidden;
 bottom: 0;
}

.thewaves {
 display: block;
 position: absolute;
 width: 100%;
 height: 100%;
 bottom: 0;
}

 ///////////////////////////////////// Services

 .serv-light, .serv-dark {
   position: relative;
   width: 100%;
   padding: 50px 5vw 50px 5vw;
   justify-content: flex-start;
   display: flex;
   flex-direction: column;
 }

 .serv-light {
   background: $medium-green;
   padding-top: 100px;

   h3 {
     color: $blue;
     font-family: 'Raleway', sans-serif;
     font-weight: 500;

     font-size: clamp(1rem, 5vw, 2.5rem);
     line-height: clamp(1.5rem, 6.5vw, 4rem);
     align-self: flex-start;
     text-align: center;
     margin-left: 30px;
     margin-right: 30px;

     span {
       color: $sienna;
     }
   }

   h3.oprocess {
     font-size: clamp(2.5rem, 8vw, 3.5rem);
     line-height: clamp(1.5rem, 6.5vw, 4rem);
     font-weight: 300;
     align-self: center;
     margin-bottom: -50px;
     margin-top: 50px;
   }

   .headcirc {
     position: relative;
     z-index: -1;
     width: clamp(15rem, 50%, 20rem);
   }

   .sectionhead {
     display: flex;
     align-self: start;
     justify-self: start;
     position: relative;
     justify-content: center;
     align-items: center;
     align-content: center;
     justify-items: center;
     z-index: 2;
     width: 100%;
   }

   h2.ourservices {
     color: $blue;
     font-size: clamp(2rem, 12vw, 5rem);
     position: absolute;
     z-index: 100;
   }

   .graphic {
     width: calc(100% - 20px);
     align-self: center;
     z-index: 100;
     margin-top: 50px;
   }
 }

 .serv-dark {
   background: $dark-green;
   display: flex;
   flex-direction: column;
   padding-bottom: 100px;
   padding-top: 60px;

   .bullet {
     margin-bottom: clamp(5rem, 20vw, 10rem);
     &:nth-of-type(5) {
       margin-bottom: 0;
     }

     h2 {
       font-family: 'Raleway', sans-serif;
       font-weight: 700;
       font-size: clamp(2rem, 8vw, 5rem);
       line-height: clamp(2rem, 8vw, 5rem);
       margin: 0 0 5vw 0;
       display: flex;
       flex-direction: column;
       color: #fff;
     }

     span.und::after {
       content:'';
       position:absolute;
       left: 0;
       bottom: 0;
       right:0;
       width: 100%;
       height: 100%;
       border-bottom: 5px solid $sienna;
       z-index: -1;
     }

     h3 {
       color: $green;
       font-family: 'Raleway', sans-serif;
       font-weight: 500;
       font-size: clamp(1.5rem, 5vw, 3rem);
       line-height: clamp(1.5rem, 5vw, 3rem);
       margin: 0 30px 5vw 30px;
       align-self: flex-start;
       font-style: italic;
       span {
         color: $sienna;
       }
     }

     h3.secondsub {
       margin-top: 5vw;
     }

     p {
       color: #fff;
       margin: 0 30px 0 30px;
       font-family: 'Raleway', sans-serif;
       font-weight: 400;
       font-size: clamp(1rem, 1vw, 1.2rem);
       line-height: clamp(2rem, 2vw, 3rem);
     }

     .wordcoop {
       font-family: 'Raleway', sans-serif;
       font-weight: 400;
       font-size: clamp(1rem, 1vw, 1.2rem);
       display: flex;
       flex-direction: column;
       gap: 12px;
     }

     .wlink {
       align-self: start;
       .wdlogo {
         height: 5rem;
         width: auto;
       }
     }
   }
  }

///////////////////////////////////// Nav

nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $dark-green;
  z-index: 101;
  transition: ease 0.4s;
  padding: 0 5vw;
}

.mob-nav {
  position: fixed;
  display: grid;
  width: 100vw;
  height: 100vh;
  background: $dark-green;
  z-index: 102;
  top: 0;
}

.menu {
  position: fixed;
  right: 0;
  width: 6rem;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 105;
  transition: easeInOut 0.2s;
  margin-right: 5vw;
}
.menu-line-wrapper {
  width: 3rem;
  height: 2rem;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  z-index: 1000;
}

.menu-line {
  width: 100%;
  height: 2px;
  background-color: #A59F49;
  transform-origin: center;
}


ul.mobile-links {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0;
}

ul.mobile-links li {
  display: inline-flex;
  padding: 0;
  margin: 2vw;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.b-link {
  color: #A59F49;
  font-family: 'preface_sansregular', sans-serif;
  font-size: 5vw;
  letter-spacing: .5px;
  transition: .25s ease;
}

.b-link:hover {
  color: #fff;
}

.home-logo {
  position: relative;
  left: 0;
  height: 70px;
  display: flex;
  align-items: center;

}

.home-logo img {
  height: 60px;
  width: auto;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: inline-block;
}

.links {
  position: absolute;
  right: 0;
  height: 70px;
  display: none;
  flex-direction: row;
  width: auto;
  margin-right: 50px;
}

ul.site-nav {
  padding: 0;
  display: inline-block;
  height: 100%;
  margin: 0;
}

ul.site-nav li {
  display: inline-flex;
  height: 100%;
  padding: 0;
  margin: 0;
  width: 150px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.a-link {
  color: #A59F49;
  font-family: 'preface_sansregular', sans-serif;
  font-size: 16px;
  letter-spacing: .5px;
  transition: .25s ease;
}

.a-link:hover {
  color: #fff;
}

.butt {
  display: flex;
}

/////////////////////////////////////// Footer

footer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $medium-green;
  width: 100%;
  padding: 100px 30px;
}

.footer-logo img {
  height: 60px;
  width: auto;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: inline-block;
}

.f-one, .f-three {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.f-one {
  justify-content: center;
}

.footer-logo {
  position: relative;
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
}

.f-two {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
  padding: 0 30px;

  h3 {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: clamp(2rem, 8vw, 2.7em);
    color: $dark-green;
    margin: 0 0 20px 0;
    text-align: center;
    width: 100%;
  }

  p {
    font-family: 'Raleway', sans-serif;
    // font-weight: 300;
    font-size: clamp(1rem, 3.5vw, 1.1rem);
    color: $dark-green;
    margin: 0;
    text-align: center;
    align-self: center;
    margin: 0 0 20px 0;
  }

  a.con {
    width: max-content;
    align-self: center;
  }

  .button {
    display: flex;
    background: rgba(156,181,158,0.3);
    border-radius: 10px;
    padding: 15px;
    width: max-content;
    margin-bottom: 20px;

    h3 {
      font-size: clamp(1.5rem, 3.5vw, 1.7rem);
      font-family: 'Raleway', sans-serif;
      font-weight: 600;
      font-style: italic;
      color: $dark-green;
      margin: 0;
    }
  }

  a.ln {
    width: 6vw;
    max-width: 40px;
    align-self: center;
  }
}

.f-three {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  border-top: 1px solid $green;

  span {
    display: block;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: clamp(.5rem, 2.5vw, .8rem);
    color: $dark-green;
    text-align: center;
  }
}




@media (min-width: 481px) {


}

@media (min-width: 620px) {

  .serv-light {
    h3.oprocess {
      font-size: clamp(2.5rem, 8vw, 3.5rem);
      line-height: clamp(1.5rem, 6.5vw, 4rem);
      font-weight: 300;
      align-self: center;
      margin-bottom: -50px;
      margin-top: 100px;
    }
  }


  .serv-dark {

    .bullet {
      .wordcoop {
        display: flex;
        flex-direction: row-reverse;
      }

      .wlink {
        .wdlogo {
          height: 7rem;
        }
      }

    }

  }

}

@media (min-width: 768px) {

  .landing {
    padding-top: 20px;
    .land2 {
      .headcirc {
        top: 0;
        left: 30%;
      }
    }
  }

  span.und::after {
    bottom: 5px;
    border-bottom: 1vw solid $sienna;
  }

  .blocks {
    margin-top: 0;
    .oneblock {
      border-radius: 40px;
      padding: 125px 50px 50px 50px;
      margin-top: 0px;
      font-size: 18px;

      &:nth-of-type(2) {
        margin: 60px auto 0 auto;
      }

      &:after {
        top: -75px;
        left: calc(50% - 75px);
        width: 150px;
        height: 150px;
      }
    }

    .onearrow {
      height: 300px;
      &:nth-of-type(2) {
        margin: 60px auto 0 auto;
      }
    }
  }

  .sidecirc {
    // max-width: 450px;
    // max-height: 450px;
    // right: -225px;
    // bottom: calc(-225px - 150px);;
  }

  .why {
    grid-gap: 75px;
    grid-template-areas:
     "header header"
     "pops pops"
     "trusted trusted"
     ". strat";

    .popwords {
      align-items: center;

      img {
        max-height: 70px;
      }

      .wordbox {
        flex-direction: row;
        gap: 20px;

        span {
          text-align: center;
        }
      }
    }

    .strategy {
      margin-left: -40px;
      padding: 40px 40px 0px 40px;
    }
  }

   .serv-light {
     .graphic {
       align-self: center;
     }
   }

   .serv-dark {
         p {
           margin: 0 30px 0 30px;
         }
       }
}

@media (min-width: 1024px) {

  .landing {
    padding-top: 20px;
  }

  .sidecirc {
    // max-width: 500px;
    // max-height: 500px;
    // right: -225px;
    // bottom: calc(-225px - 150px);;
  }

  .par {
    margin-top: 3vw;
  }

  .customers {
     .reviews {
       &:after {
         top: 18vw;
         height:80%;
       }
     }
   }

   .serv-light {
     .graphic {
       align-self: center;
     }
   }

   .par.ab {
     max-width: 800px;
     padding: 60px 60px 100px 60px;
   }

   .serv-dark {
       h2 {
         margin: 0 0 3vw 0;
       }

       h3 {
         margin: 0 30px 3vw 30px;
       }

       p {
         margin: 0 30px 0 30px;
         width: 50%;
       }
    }

  .f-one {
    justify-content: flex-start;
    padding: 0 30px;
  }

  .f-two {
    h3 {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 30px;
    }

    .button {
      margin-bottom: 30px;
    }
  }

  .f-three {
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;

    span {
      text-align: left;
    }
  }

}


@media (min-width: 1366px) {

  .nav {
    padding: 0 100px;
  }

  .mob-nav {
    display: none;
  }

  .menu {
    display: none;
  }

  .links {
    display: flex;
  }

  .landing {
    padding-top: 20px;
    .land2 {
      p {
        padding: 0 0 0 20px;
      }
    }
  }

  .wavesbox {
  height: 40vh;
  }

  .aboutwaves {
  height: 40vh;
  }

  .icons {
    height: 20vh;
  }

  .needs, .why, .customers {
    padding: 100px 100px 100px 100px;
  }

  .blocks {
    .oneblock {
      border-radius: 40px;
      padding: 125px 50px 50px 50px;
      margin-top: 0px;
      font-size: 18px;

      &:nth-of-type(2) {
        margin: 60px auto 0 auto;
      }

      &:after {
        top: -75px;
        left: calc(50% - 75px);
        width: 150px;
        height: 150px;
      }
    }
  }

  .why {
    grid-gap: 150px;
    grid-template-areas:
     "header header"
     "pops pops"
     "trusted trusted"
     ". strat";

    .popwords {
      align-items: center;

      img {
        height: 75%;
        max-height: 150px;
      }

      .wordbox {
        flex-direction: row;
        gap: 100px;

        span {
          // font-size: 1vw;
          text-align: center;
        }
      } //end .wordbox
    } //end .popwords

    h3 {
      margin: 0 100px;
    }

    .strategy {
      margin-left: -100px;
      padding: 50px 50px;

      &:after {
        padding-bottom: 250px;
      }

      .sqcrcl {
        top: calc(100% + 212.5px);
      }
    } //end .strategy
  } //end .why

  .customers {
     .reviews {
       padding: 100px;
       &:after {
         top: 18vw;
         height: 67%;
       }
     }
   }

   footer {
     padding: 50px 100px;
   }

   .f-one, .f-three {
     padding-left: 100px;
     padding-right: 100px;
   }

   .f-three {
     span {
       text-align: left;
     }
   }

   .serv-light {
     .graphic {
       width: calc(100% - 300px);
       align-self: center;
     }

     h3 {
       margin-left: 175px;
       margin-right: 175px;
     }
   }

   .par.ab {
     max-width: 1000px;
   }

   .serv-dark {
       h2 {
         margin: 0 0 5vw 0;
       }

       h3 {
         margin: 0 100px 5vw 100px;
       }

       p {
         line-height: 30px;
       }

    }

}
